import * as environment from "../../../config/environment.json";
import {translations} from "../classes/translations";
import {I18N} from "aurelia-i18n";
import * as Fhir from "../classes/FhirModules/Fhir";
import {Tools} from "../classes/FhirModules/Fhir";
import {NitTools} from "../classes/NursitTools";
import {IFormSetting} from "../classes/IFormSettings";
import {UserService} from "./UserService";
import {ReportService} from "./ReportService";
import {RuntimeInfo} from "../classes/RuntimeInfo";
import {HttpClient} from "aurelia-http-client";
import {QuestionnaireService} from "./QuestionnaireService";
import {FhirService} from "./FhirService";
import {PatientInformation} from "../elements/patient-overview/patient-information";
import {AnalyzeService} from "./analyzeService";
import {FormBaseClass} from "../elements/FormBaseClass";
import {PatientDischarge} from "../../views/patient/discharge";
import {ConfigMain} from "../../views/admin/config/config-main";
import {PatientCurveVitalchart} from "../elements/patient-curve-vitalchart";
import {PatientMedication} from "../elements/patient-overview/patient-medication";
import {ExtendedNavigationView} from "../../views/extendedNavigationView";
import {IPegasosConfig} from "./ConfigClasses/pegasos-config";
import {PatientItem} from "../classes/Patient/PatientItem";

const moment = require("moment");
const md = require("mobile-detect");

export class ConfigService {
    private static _fhirConfigJson: string;
    private static _debug: boolean;
    private static HttpCache: { url: string, response: string }[] = [];
    private static _useAssessmentForSignal10: boolean = true;
    private static testingLocalServer: boolean = false;

    public static NursItQuestionnaireCanonical: string = "http://nursit-institute.com/fhir/Questionnaire";
    public static fhirConfigLoaded: boolean = false;
    public static loadingFhirConfig: boolean = false;
    public static i18n: I18N;
    public static serverUp: boolean = undefined;
    public static EncounterTypeChoice: IEncounterTypeChoice = { enabled: false, displayIcons: false };
    public static UseOAuth2: boolean = false; // indicates whether the OAuth2 Login is enabled
    public static AccessToken: string;  // the Accesstoken to use from the OAuth2 Token
    public static IdToken: string; // the Id-Token to use from the OAuth2 Token
    public static TokenExpirationSeconds: number = 3599;    // the duration in seconds indicating how long the token is valid
    public static TokenType: string;    // the token type returned from the OAuth2-Server
    public static RefreshToken: string; // the Refresh-Token ID. For getting this the scope online_access or offline_access have to be included
    public static PkmsGroupName: string;
    public static ConfigOverrides: {};
    public static UseBarthelIndexForFlag5: boolean = true;
    public static UseIsolationForFlag1: boolean = true;
    public static IsTest: boolean = false;
    public static FormSettings: IFormSetting[];
    public static DebugFhirRequests: boolean = false;
    public static DebugFhirResponses: boolean = false;
    public static AutomaticallyJumpToFirstEncounter: boolean = true;
    public static FhirDevice: any;
    public static FhirDeviceComponent: any;
    public static OfflineModeConfiguredAsEnabled: boolean;
    public static defaultSource: string = undefined;
    public static ConfigLoaded: boolean = false;
    public static Pegasos: IPegasosConfig;
    public  static displayNitLogo: boolean = false;
    public  static displayCaseIdentifier: boolean = false;
    public static displayUserMenu: boolean = true;
    public cfg: any;
    public static default_care_targetValues : number[] = [1,3,5,7];
    public static CareTargetValues : number[] = this.default_care_targetValues;

    public static FormNames = {
        Anamnesis: "anamnesis",
        Assessment: "assessment",
        Medications: "medications",
        PhysioAnamnesis: "physioanamnese",
        Analysis: "analysis",
        Planning: "planning",
        Evaluation: "evaluation",
        CareProcess: "careprocess",
        Wounds: "wounds",
        Curve: "curve",
        CareFiles: "carefiles",
        BedOverview: "ward",
        CareIn: "carein",
        Fall: "fall",
        Isolation: "isolation",
        Transfer: "transfer",
        Details: "details",
        Discharge: "discharge",
        BarthelIndex: "barthelindex",
        BarthelIndexEx: "barthelindexEx",
        FIM: "fim",
        WardShiftReport: "ward-shift-report",
        Ward: "ward",
        DoctorOverview: "doctor-overview",
    };

    public static DefaultViewLocations = [
        { "route": "FORMS_DEFAULT", "location": "none" },
        { "route": "CareIT_DEMMI", "location": "none" },
        { "route": "CareIT_EAT", "location": "none" },
        { "route": "CareIT_MNA", "location": "none" },
        { "route": "anamnesis", "location": "top" },
        { "route": "assessment", "location": "top" },
        { "route": "physioanamnese", "location": "menu" },
        { "route": "fall", "location": "menu" },
        { "route": "wounds", "location": "bottom" },
        { "route": "isolation", "location": "menu" },
        { "route": "transfer", "location": "menu" },
        { "route": "discharge", "location": "none" },
        { "route": "barthelindex", "location": "none" },
        { "route": "barthelindexEx", "location": "none" },
        { "route": "fim", "location": "none" },
        { "route": "grafixx", "location": "none" },
        { "route": "analysis", "location": "top" },
        { "route": "planning", "location": "top" },
        { "route": "evaluation", "location": "top" },
        { "route": "curve", "location": "bottom" },
        { "route": "careprocess", "location": "bottom" },
        { "route": "carefiles", "location": "bottom" },
        { "route": "ward", "location": "none" },
        { "route": "carein", "location": "none" },
        { "route": "medications", "location": "bottom" },
        { "route": "details", "location": "bottom" }
    ];

    public static set Debug(value: boolean) {
        this._debug = value;
    }

    public static get Debug(): boolean {
        return typeof this._debug !== "undefined" ? this._debug : environment.debug
    }

    public static get SubDelegationConfig() : any {
        return this.cfg?.features?.subdelegation || { enabled: false };
    }

    public get fhirConfigJson(): string {
        return ConfigService._fhirConfigJson;
    }

    public get device(): any {
        return ConfigService.FhirDevice;
    }

    public get component(): any {
        return ConfigService.FhirDeviceComponent;
    }

    public static get ChatEnabled() {
        return ConfigService.cfg.features && ConfigService.cfg.features.chat && ConfigService.cfg.features.chat.enabled || false;
    }

    public static get ChatURL() {
        return ConfigService.cfg && ConfigService.cfg.features && ConfigService.cfg.features.chat && ConfigService.cfg.features.chat.url || '';
    }

    // -----------------------------------------
    constructor() {

    }

    public static GetFormSettings(route: string, patient? : PatientItem): IFormSetting {
        if (!route) {
            route = "default";
        }

        if (!patient) {
            patient = PatientItem.LastLoadedPatient;
        }

        let setting: IFormSetting;
        const override = this.ConfigOverrides[patient?.ward?.toUpperCase()];
        if (typeof override !== "undefined" && patient?.ward) {
            if (override.forms) {
                const forms = override.forms;
                if (forms) {
                    setting = forms.find(o => o.route && o.route.toUpperCase() === route.toUpperCase());
                }
            }
        }

        if (typeof setting === "undefined") {
            setting = this.FormSettings.find((o: IFormSetting) => o.route.toUpperCase() === route.trim().toUpperCase());
        }

        if (setting && !setting.viewMode)
            setting.viewMode = 'combo';

        return setting;
    }

    public static GetFormSettingsByQuestionnaireName(questionnaireName: string): IFormSetting {
        if (!questionnaireName) return undefined;
        let setting: IFormSetting;
        const override = this.ConfigOverrides[PatientItem.LastLoadedPatient?.ward?.toUpperCase()];
        if (typeof override !== "undefined" && typeof override.forms !== "undefined") {
            const form = override.forms.find(o => o.questionnaireName && o.questionnaireName.toUpperCase() === questionnaireName.toUpperCase())
            if (typeof form !== "undefined")
                return form;
        }

        return this.FormSettings.find((o: IFormSetting) => o.questionnaireName && o.questionnaireName.trim().toUpperCase() === questionnaireName.trim().toUpperCase());
    }

    public static IsFormEnabled(formName: string) {
        let setting = ConfigService.GetFormSettings(formName);
        if (!setting) return true;
        return setting.enabled;
    }

    public static SetupI18n(i18n: I18N) {
        return new Promise((resolve, reject) => {
            i18n.i18next.loadResources(() => {
                i18n.setLocale(RuntimeInfo.Language).then(() => {
                    translations.language = RuntimeInfo.Language;
                    translations.i18 = i18n.i18next;
                    this.setMomentOptions();

                    // this.setPickerOptions();
                    ConfigService.i18n = i18n;
                    resolve(true);
                }).catch((e) => {
                    reject(e);
                });
            });
        });
    }

    private static waitForService(): Promise<any> {
        return new Promise<any>(async (resolve) => {
            if (!this.loadingFhirConfig) {
                resolve(true);
            } else {
                window.setTimeout(async () => {
                    await this.waitForService();
                    resolve(true);
                }, 100);
            }
        })
    }

    private static setMomentOptions() {
        moment.updateLocale(RuntimeInfo.Language, {
            week: { dow: 1, doy: 6 },
            weekdays: [translations.translate('day_7_long'),
            translations.translate('day_1_long'),
            translations.translate('day_2_long'),
            translations.translate('day_3_long'),
            translations.translate('day_4_long'),
            translations.translate('day_5_long'),
            translations.translate('day_6_long'),
            ],
            weekdaysMin: [translations.translate('day_7'),
            translations.translate('day_1'),
            translations.translate('day_2'),
            translations.translate('day_3'),
            translations.translate('day_4'),
            translations.translate('day_5'),
            translations.translate('day_6'),
            ],
            weekdaysShort: [translations.translate('day_7'),
            translations.translate('day_1'),
            translations.translate('day_2'),
            translations.translate('day_3'),
            translations.translate('day_4'),
            translations.translate('day_5'),
            translations.translate('day_6'),
            ],
            months: [
                translations.translate('month_1'),
                translations.translate('month_2'),
                translations.translate('month_3'),
                translations.translate('month_4'),
                translations.translate('month_5'),
                translations.translate('month_6'),
                translations.translate('month_7'),
                translations.translate('month_8'),
                translations.translate('month_9'),
                translations.translate('month_10'),
                translations.translate('month_11'),
                translations.translate('month_12'),
            ],
            monthsShort: [
                translations.translate('month_1_short'),
                translations.translate('month_2_short'),
                translations.translate('month_3_short'),
                translations.translate('month_4_short'),
                translations.translate('month_5_short'),
                translations.translate('month_6_short'),
                translations.translate('month_7_short'),
                translations.translate('month_8_short'),
                translations.translate('month_9_short'),
                translations.translate('month_10_short'),
                translations.translate('month_11_short'),
                translations.translate('month_12_short'),
            ],
            invalidDate: translations.translate('invalid_date'),
        });
    }

    static get cfg(): any {
        return this.ConfigOverrides?.["default"];
    }

    public static EnsureTokens() {
        if (!ConfigService.AccessToken) {
            const storedData = sessionStorage.getItem(environment.oauth2SessionName);
            if (storedData) {
                const tokenData = JSON.parse(storedData);
                ConfigService.SetOAuthTokenProperties(tokenData);
            }
        }
    }

    /**
     * Simply assigns the values from the given OAuth-roken Response
     * to the ConfigService Properties
     * @param oauthObject the OAuth-Object to get the values from
     */
    public static SetOAuthTokenProperties(oauthObject: any) {
        if (typeof (oauthObject) === "string")
            oauthObject = JSON.parse(oauthObject);

        sessionStorage.setItem(environment.oauth2SessionName, JSON.stringify(oauthObject));

        ConfigService.AccessToken = oauthObject.access_token;
        ConfigService.IdToken = oauthObject.id_token;
        ConfigService.TokenExpirationSeconds = oauthObject.expires_in;
        ConfigService.TokenType = oauthObject.token_type;
        ConfigService.RefreshToken = oauthObject.refresh_token;
    }

    private static GetConfigSetting(setupKeyName: string, config: string = "default", defaultValue: any = undefined) {
        let val: any;
        try {
            if (typeof config === "string" && config !== "default")
                config = config.toUpperCase();

            if (typeof this.ConfigOverrides !== "undefined"
                && typeof this.ConfigOverrides[config] !== "undefined"
                && typeof this.ConfigOverrides[config][setupKeyName] !== "undefined") {
                val = this.ConfigOverrides[config][setupKeyName];
            }

            if (typeof val === "undefined") val = environment[setupKeyName];
            if (typeof val === "undefined" && typeof setupKeyName === "string"
                && typeof environment !== "undefined"
                && typeof environment['default'] !== "undefined"
                && ((typeof environment['default'][setupKeyName] !== "undefined") || (typeof environment['default'][setupKeyName.toUpperCase()] !== "undefined"))
            ) {
                val = environment['default'][setupKeyName] || environment['default'][setupKeyName.toUpperCase()];
            }

            if (typeof val === "undefined") val = defaultValue;
        } catch (e) {
            debugger;
        }

        return val;
    }

    public static get ConfigName(): string {
        let s = this.GetConfigName();
        if (s.indexOf('?') > -1) s = s.split('?')[0];

        return s;
    }

    private static GetConfigName() {
        let cfgFile = `config/setup.json?_=${new Date().valueOf()}`;

        let params = NitTools.GetUrlParams();
        if (params.config) {
            cfgFile = `config/customer_config/setup.${params.config}.json?_=${new Date().valueOf()}`;
        }

        return cfgFile;
    }

    public static get UseAssessmentForSignal10(): boolean {
        return this._useAssessmentForSignal10;
    }

    public static set UseAssessmentForSignal10(value: boolean) {
        if (this._useAssessmentForSignal10 === value)
            return;

        this._useAssessmentForSignal10 = value;
        const className = 'no-assessment-for-10';
        if (!value)
            document.body.classList.add(className);
        else
            document.body.classList.remove(className);
    }

    public static async LoadConfigOverride(wardNameUncased: string, patient? : PatientItem): Promise<any> {
        let tabs: number = -1;
        //#region Helper Functions
        const recurseSetObjectValue = (source, target) => {
            tabs++;
            let preFix = '';
            for (let i = 0; i < tabs; i++)
                preFix = preFix + "\t";

            for (const sourceKey of Object.keys(source)) {
                const targetProperty = target[sourceKey];
                if (typeof targetProperty === "undefined") {
                    // target does not exist, so simply set it
                    target[sourceKey] = source[sourceKey];
                } else {
                    // when target exists ..
                    const sourceProperty = source[sourceKey];
                    if (typeof sourceProperty === "object") {
                        // .. when it is an object, recusively set the properties:
                        recurseSetObjectValue(source[sourceKey], target[sourceKey]);
                    } else {
                        // not object or array then just copy
                        target[sourceKey] = source[sourceKey];
                    }
                }
            }

            tabs--;
        }
        const getQID = function (config, routeName) {
            if (config && config.forms) {
                const setting = config.forms.find(o => o.route && o.route.toUpperCase() === routeName.toUpperCase());
                if (setting && setting.questionnaireName) {
                    let qA = QuestionnaireService.GetQuestionnaireByNameDirect(setting.questionnaireName);
                    if (qA) return qA.id;
                }
            }

            return undefined;
        }
        const updateQuestionnaireIds = (js) => {
            QuestionnaireService.__listResult.QAssessmentId = getQID(js, 'assessment') || QuestionnaireService.__listResult.QAssessmentId;
            QuestionnaireService.__listResult.QAnamnesisId = getQID(js, 'anamnesis') || QuestionnaireService.__listResult.QAnamnesisId;
            QuestionnaireService.__listResult.QBarthelIndexId = getQID(js, 'barthelindex') || QuestionnaireService.__listResult.QBarthelIndexId;
            QuestionnaireService.__listResult.QBarthelIndexExId = getQID(js, 'barthelindexEx') || QuestionnaireService.__listResult.QBarthelIndexExId;
            QuestionnaireService.__listResult.QFimId = getQID(js, 'FIM') || QuestionnaireService.__listResult.QFimId;
            QuestionnaireService.__listResult.QFallId = getQID(js, 'fall') || QuestionnaireService.__listResult.QFallId;
            QuestionnaireService.__listResult.QIsolationId = getQID(js, 'isolation') || QuestionnaireService.__listResult.QIsolationId;
            QuestionnaireService.__listResult.QDiagnosisId = getQID(js, 'diagnosis') || QuestionnaireService.__listResult.QDiagnosisId;
            QuestionnaireService.__listResult.QDischargeManagementId = getQID(js, 'discharge') || QuestionnaireService.__listResult.QDischargeManagementId;
            QuestionnaireService.__listResult.QVerlegungId = getQID(js, 'transfer') || QuestionnaireService.__listResult.QVerlegungId;
        }
        //#endregion

        try {
            await this.waitForService();
            this.loadingFhirConfig = true;
            await this.ReadDefaultConfig();

            const resultingConfig = JSON.parse(this.defaultSource);
            updateQuestionnaireIds(resultingConfig); // restore default Questionnaire-Ids

            if (!wardNameUncased || typeof wardNameUncased !== "string" || !this.i18n)
                return resultingConfig;

            const wardName: string = wardNameUncased.toUpperCase();

            const configOverride = JSON.parse(await this.LoadConfigFromHttp(wardName));
            if (configOverride["forms"]) {
                for (const configForm of configOverride["forms"]) {
                    const defaultForm = resultingConfig.forms.find(o => o.route.toUpperCase() === configForm.route.toUpperCase());
                    if (typeof defaultForm !== "undefined") {
                        Object.assign(defaultForm, configForm);
                    } else
                        resultingConfig.forms.push(configForm)
                }
            }

            if (configOverride["features"]) {
                for (const key of Object.keys(configOverride["features"])) {
                    resultingConfig.features[key] = configOverride["features"][key];
                    RuntimeInfo.Features[key] = configOverride["features"][key];
                }

                ConfigService.displayNitLogo = typeof(configOverride["features"]["display_nit_logo"]) === "boolean" ? configOverride["features"]["display_nit_logo"] : false;
                ConfigService.displayCaseIdentifier = typeof(configOverride["features"]["display_case_identifier"]) === "boolean" ? configOverride["features"]["display_case_identifier"] : false;
                ConfigService.displayUserMenu = typeof(configOverride["features"]["display_user_menu"]) === "boolean" ? configOverride["features"]["display_user_menu"] : true;
            }
            //const overrides = JSON.parse(this.defaultSource)["wardOverrides"];

            if (!this.ConfigOverrides) {
                this.ConfigOverrides = {};
            }

            this.ConfigOverrides[wardName?.toUpperCase()] = resultingConfig;

            updateQuestionnaireIds(resultingConfig);

            if (resultingConfig && resultingConfig.features && resultingConfig.features.encounterTypeChoice) {
                ConfigService.EncounterTypeChoice = resultingConfig.features.encounterTypeChoice;
            } else {
                ConfigService.EncounterTypeChoice = { enabled: false, displayIcons: false };
            }

            if (patient && ConfigService.EncounterTypeChoice?.enabled) {
                const item = PatientItem.GetEncounterChoiceItem(patient);

                if (item) {
                    if (item.forms) {
                        for (const route of Object.keys(item.forms)) {
                            const target = resultingConfig.forms.find(o => o.route?.toUpperCase() === route.toUpperCase());
                            if (target) {
                                const targetIndex = resultingConfig.forms.indexOf(target);
                                recurseSetObjectValue(item.forms[route], resultingConfig.forms[targetIndex]);
                            }
                        }
                    }
                }
            }

            return resultingConfig;
        } finally {
            this.loadingFhirConfig = false;
        }
    }

    private static async LoadConfigFromHttp(wardName: string): Promise<string> {
        const wardUrl: string = this.GetConfigFileUrl(wardName);
        let result: string = this.defaultSource;

        // try to get the config from cache
        const existing = this.HttpCache.find(o => o.url === wardUrl);
        if (typeof existing !== "undefined" && existing.response)
            return existing.response;

        // generate a valid http filename and download that, pushing it to the http-cache
        try {
            const httpResponse = await new HttpClient().get(`${wardUrl}?_=${new Date().valueOf()}`);
            if (httpResponse.statusCode !== 200) {
                if (ConfigService.Debug && httpResponse.statusCode !== 404) {
                    throw `Got Status ${httpResponse.statusCode} for file "${wardUrl}"`;
                }
            } else {
                JSON.parse(httpResponse.response); // to throw exception if not valid
                result = httpResponse.response;
                if (typeof this.HttpCache.find(o => o.url === wardUrl) === "undefined")
                    this.HttpCache.push({ url: wardUrl, response: httpResponse.response });
            }
        } catch (error) {
            if (typeof this.HttpCache.find(o => o.url === wardUrl) === "undefined")
                this.HttpCache.push({ url: wardUrl, response: this.defaultSource.trim() });
        }

        return result;
    }

    public static ParseUAString(value?: string) {
        if (!value) value = navigator.userAgent;
        let result = {
            isPhone: (/Phone/gi.test(value)),
            isIPad: (/iPad/gi.test(value)),
            isPhoneOrPad: (/Mobile/gi.test(value)),
            isWin: (/Windows/gi.test(value)),
            isLinux: (/Linux/gi.test(value)),
            isAndroid: (/Android/gi.test(value)),
            isMac: (/Macintosh/gi.test(value)),
            isKindle: (/Silk[-\/]/gi.test(value)),
            userAgent: value,
            offLineSupported: true
        }

        if (result.isIPad || result.isKindle || result.isPhone || result.isAndroid) result.isPhoneOrPad = true;

        result.offLineSupported = result.isWin || (result.isLinux && !result.isAndroid) || result.isMac;
        result.offLineSupported = result.offLineSupported && !result.isPhoneOrPad;

        Object.keys(result).forEach(key => {
            if (result[key] === true) document.body.classList.add(key);
        })

        return result;
    }

    public static GetConfigFileUrl(wardNameUncased: string): string {
        let configFileName = String(wardNameUncased).trim().replace(/ {2}/g, ' ')
            .replace(/[ \\!"'§$%&()\[\]=\?{}`´<>|;,:\.@]/gi, '_')
            .trim().toUpperCase();
        if (!/\.json$/gi.test(configFileName))
            configFileName += '.json';

        return `config/ward_configs/${configFileName}`;
    }

    public static async ReadDefaultConfig(): Promise<string> {
        let configResult: string = undefined;
        this.CareTargetValues = this.default_care_targetValues;
        this.ConfigLoaded = true;
        this.ConfigOverrides["default"] = JSON.parse(this.defaultSource || '{}');

        // reset the default IDs in the QuestoinnaireService
        if (UserService.IsLoggedIn) {
            await QuestionnaireService.Fetch();
            let cfgAssessment = this.ConfigOverrides["default"].forms.find(o => o.route === 'assessment');
            let qAssessment = await QuestionnaireService.GetQuestionnaireByName(cfgAssessment.questionnaireName);
            QuestionnaireService.__listResult.QAssessmentId = qAssessment.id;

            let cfgAnamnesis = this.ConfigOverrides["default"].forms.find(o => o.route === 'anamnesis');
            let qAnamnesis = await QuestionnaireService.GetQuestionnaireByName(cfgAnamnesis.questionnaireName);
            QuestionnaireService.__listResult.QAnamnesisId = qAnamnesis.id;
        }

        /* spread the properties from the cfg */
        ConfigService.Debug = NitTools.ParseBool(this.GetConfigSetting("debug")) || window.location.href.indexOf('debug=1') > -1;
        if (window.location.href.indexOf('debug=0') > -1)
            ConfigService.Debug = false;

        ConfigService.NursItQuestionnaireCanonical = this.GetConfigSetting("nursItQuestionnaireCanonicalBase", "http://nursit-institute.com/fhir/Questionnaire");
        ConfigService.DebugFhirRequests = NitTools.ParseBool(this.GetConfigSetting("debugFhirRequests"))
        ConfigService.DebugFhirResponses = NitTools.ParseBool(this.GetConfigSetting("debugFhirResponses"))

        QuestionnaireService.UseFhirVersionSystem = this.GetConfigSetting("useFhirVersionSystem");
        UserService.Login = NitTools.Clone(this.GetConfigSetting("login"));
        UserService.AdminUsers = NitTools.Clone(this.GetConfigSetting('adminUsers'));

        let tmpOffline = this.GetConfigSetting('offlineClient');
        if (tmpOffline) {
            FhirService.OfflineClientSettings = tmpOffline;

            if (FhirService.OfflineClientSettings && FhirService.OfflineClientSettings.enabled && !FhirService.OfflineClientSettings.isOffline) {
                const ua = this.ParseUAString(navigator.userAgent);
                this.OfflineModeConfiguredAsEnabled = true;
                if (!ua.offLineSupported) {
                    FhirService.OfflineClientSettings.enabled = false;
                    FhirService.OfflineClientSettings.isOffline = false;

                    if (ConfigService.Debug) {
                        console.info(`Not enabling Offline Client because this is not a supported Client`);
                    }
                } else if (!ConfigService.testingLocalServer) {
                    ConfigService.testingLocalServer = true;    // do not set this to false again to avoid multiple checking
                    // offline is supported, so let's check if the local server is reachable:
                    let url = `${NitTools.ExcludeTrailingSlash(FhirService.OfflineClientSettings.local.fhirServer)}/endpoint-health`;
                    try {
                        let client = new HttpClient();
                        client.configure(x => {
                            x.withTimeout(3000);
                        })

                        await client.get(url);
                    } catch (error) {
                        console.warn(`Local Fhir-Server @ ${url} is not reacheable. Disabling offline client`, error);
                        if (FhirService.OfflineClientSettings.isOffline === false && FhirService.OfflineClientSettings.warn)
                            configResult = 'could_not_connect_local_server';
                        FhirService.OfflineClientSettings.isOffline = false;
                        FhirService.OfflineClientSettings.enabled = false;
                    }
                }
            } else {
                this.OfflineModeConfiguredAsEnabled = false;
            }

            // if (ConfigService.Debug) console.debug(`Offline Client\nEnabled: ${FhirService.OfflineClientSettings.enabled}\nFhirServer: ${FhirService.OfflineClientSettings.fhirServer}\nlocalClient: ${FhirService.OfflineClientSettings.localUrl}`);
        }

        if (!RuntimeInfo.AppVersion) {
            const rsultMessage = await new HttpClient().get('app/build.json?_=' + new Date().valueOf());
            if (rsultMessage.statusCode === 200) {
                const js = JSON.parse(rsultMessage.response);
                RuntimeInfo.AppVersion = js.build;
            } else RuntimeInfo.AppVersion = 'Build.Json not found';
        }

        FhirService.EmbeddedUserHash = this.GetConfigSetting("embeddedUserHash");
        FhirService.UserNodeId = this.GetConfigSetting("fhirUserNodeId");
        FhirService.UserModuleId = this.GetConfigSetting("fhirUserModuleId");
        FhirService.EncounterAdditionalFilters = this.GetConfigSetting("encounterAdditionalFilters");
        FhirService.SessionName = this.GetConfigSetting("sessionName");
        PatientInformation.MainDiagnosisRank = this.GetConfigSetting('mainDiagnosisRank');
        ReportService.ReportServer = this.GetConfigSetting("reportServer");
        ConfigService.FormSettings = this.GetConfigSetting("forms");
        RuntimeInfo.Language = this.GetConfigSetting("language");
        RuntimeInfo.DateFormat = this.GetConfigSetting('dateFormat');
        RuntimeInfo.TimeFormat = this.GetConfigSetting("timeFormat");
        RuntimeInfo.DateTimeFormat = this.GetConfigSetting("dateTimeFormat");
        RuntimeInfo.PkmsRelevanceStart = this.GetConfigSetting("pkmsRelevanceStart");
        RuntimeInfo.SystemHeader = this.GetConfigSetting("systemHeader");
        RuntimeInfo.EncounterDateFormat = this.GetConfigSetting("encounterDateFormat");
        RuntimeInfo.Features = NitTools.Clone(this.GetConfigSetting('features'));
        AnalyzeService.PkmsRelevanceStart = this.GetConfigSetting('pkmsRelevanceStart');
        UserService.ValidationRequired = this.GetConfigSetting('validationRequired');
        ReportService.ReportServer = this.GetConfigSetting("reportServer");
        Tools.UseEncounterPeriod = this.GetConfigSetting('useEncounterPeriod');
        Fhir.Encounter.UseEncounterEndFilter = this.GetConfigSetting('useEncounterEndFilter');
        //        FormBaseClass.UseQuestionnaireStatusForSave = this.getConfigSetting('useQuestionnaireStatusForSave');
        FormBaseClass.UseQuestionnaireStatusForSave = true;
        PatientDischarge.Recare = NitTools.Clone(this.GetConfigSetting('recare'));
        Fhir.Tools.Skeletons = NitTools.Clone(this.GetConfigSetting('skeletons'));
        ConfigMain.UseFhirConfig = this.GetConfigSetting('useFhirConfig');
        PatientCurveVitalchart.AlphaTronVitals = NitTools.Clone(this.GetConfigSetting('alphaTronVitals'));
        PatientMedication.AlphaTron = NitTools.Clone(this.GetConfigSetting('alphaTron'));
        ExtendedNavigationView.AutoGenerateNonExistingPractitioners = this.GetConfigSetting('autoGenerateNonExistingPractitioners');

        let params = NitTools.GetUrlParams();
        RuntimeInfo.Style = params["style"] ? params["style"] : (this.GetConfigSetting('style') || 'default');
        this.PkmsGroupName = this.GetConfigSetting('pkmsGroupName');

        /*++++++++++++++++++++++++++++++*/
        let localFhirServer = this.cfg.fhirServer;
        let localFhirAdmin = this.cfg.fhirAdmin;
        let remoteFhirServer = undefined;
        let remoteFhirAdmin = undefined;
        let localFhirUserModuleId = this.cfg.fhirUserModuleId;
        let remoteFhirUserModuleId = undefined;
        let localFhirUserNodeId = this.cfg.fhirUserNodeId;
        let remoteFhirUserNodeId = undefined;
        let proxySetting = this.cfg.dataProxy;
        if (proxySetting) RuntimeInfo.DataProxy = NitTools.Clone(proxySetting);

        if (typeof this.cfg.fhirServer === "object") {
            remoteFhirServer = this.cfg.fhirServer["remote"];
            localFhirServer = this.cfg.fhirServer["local"];
        }

        if (typeof this.cfg.fhirAdmin === "object") {
            remoteFhirAdmin = this.cfg.fhirAdmin["remote"];
            localFhirAdmin = this.cfg.fhirAdmin["local"];
        }

        if (typeof this.cfg.fhirUserNodeId === "object") {
            remoteFhirUserNodeId = this.cfg.fhirUserNodeId["remote"];
            localFhirUserNodeId = this.cfg.fhirUserNodeId["local"];
        }

        if (typeof this.cfg.fhirUserModuleId === "object") {
            remoteFhirUserModuleId = this.cfg.fhirUserModuleId["remote"];
            localFhirUserModuleId = this.cfg.fhirUserModuleId["local"];
        }

        if (this.cfg.features) {
            if (typeof this.cfg.features.automaticallyJumpToFirstEncounter === "boolean")
                this.AutomaticallyJumpToFirstEncounter = this.cfg.features.automaticallyJumpToFirstEncounter;
            ConfigService.displayNitLogo = typeof this.cfg.features.display_nit_logo === "boolean" ? this.cfg.features.display_nit_logo : false;
            ConfigService.displayCaseIdentifier = typeof this.cfg.features.display_case_identifier === "boolean" ? this.cfg.features.display_case_identifier : false;
            ConfigService.displayUserMenu = typeof this.cfg.features.display_user_menu === "boolean" ? this.cfg.features.display_user_menu : true;

        }

        FhirService.Endpoint = localFhirServer;
        FhirService.AdminEndpoint = localFhirAdmin;

        FhirService.UserNodeId = localFhirUserNodeId;
        FhirService.UserModuleId = localFhirUserModuleId;
        FhirService.RemoteSetup = {
            fhirUserModuleId: remoteFhirUserModuleId,
            fhirUserNodeId: remoteFhirUserNodeId,
            fhirAdmin: remoteFhirAdmin,
            fhirServer: remoteFhirServer
        };

        //#region Read/Create Nexos Config
        if (!ConfigService.Pegasos) {
            ConfigService.Pegasos = {
                enabled: false
            }

            const _cfg = this.ConfigOverrides["default"];
            if (_cfg && _cfg.marabu && _cfg.marabu.pegasos) {
                ConfigService.Pegasos = NitTools.Clone(_cfg.marabu.pegasos);
            }
        }
        //#endregion

        if (typeof this.HttpCache.find(o => o.url === 'config/setup.json') === "undefined")
            this.HttpCache.push({ url: 'config/setup.json', response: JSON.stringify(this.cfg) });

        if (this.cfg.features) {
            if (typeof this.cfg.features.useIsolationForSignal1 === "boolean") {
                ConfigService.UseIsolationForFlag1 = this.cfg.features.useIsolationForSignal1;
            }

            if (typeof this.cfg.features.useBarthelIndexForSignal5 === "boolean") {
                ConfigService.UseBarthelIndexForFlag5 = this.cfg.features.useBarthelIndexForSignal5;
            }

            if (typeof this.cfg.features.useAssessmentForSignal10 === "boolean") {
                ConfigService.UseAssessmentForSignal10 = this.cfg.features.useAssessmentForSignal10;
            }

            if (NitTools.IsArray(this.cfg.features.targetValues)) {
                this.CareTargetValues = this.cfg.features.targetValues;
            }
        }

        if (this.cfg["wardOverrides"]) {
            if (!this.ConfigOverrides)
                this.ConfigOverrides = {};

            //try {
            for (const wdCfg of this.cfg["wardOverrides"].filter(o => o.wardName && o.url)) {
                if (typeof wdCfg["wardName"] === "string") {
                    await this.LoadConfigFromHttp(wdCfg["wardName"]);
                } else if (NitTools.IsArray(wdCfg["wardName"])) {
                    const cfg = await this.LoadConfigFromHttp(wdCfg["url"]);
                    for (const str of wdCfg["wardName"]) {
                        const _url = this.GetConfigFileUrl(str);
                        if (typeof this.HttpCache.find(o => o.url === _url) === "undefined")
                            this.HttpCache.push({ url: _url, response: cfg });
                    }
                }
            }
            /*} catch (e) {
                console.warn(e.message || e);
            }*/
        }

        return configResult;
    }

    public static async Load(i18n: I18N): Promise<string> {
        this.i18n = i18n;

        // get default config
        let client = new HttpClient();
        if (!this.ConfigOverrides) this.ConfigOverrides = {};

        if (!this.cfg) {
            const name = this.GetConfigName();
            let result = await client.get(name);
            try {
                let cfg = JSON.parse(result.response);
                this.ConfigOverrides["default"] = NitTools.Clone(cfg);
                this.defaultSource = JSON.stringify(cfg);
            } catch (e) {
                this.defaultSource = '{}';
                const msg = `Config-File "${name}" is not valid and can not be read.\nCareIt will not work!\n\n<b>Fix the Config-File and reload the page.</b>\n\nError recieved was: \n<pre>${e.message || JSON.stringify(e)}</pre>`;
                window.document.body.innerHTML = ` <div class="container" style="padding-top:2em">
                                                        <div class="panel panel-danger">
                                                            <div class="panel-heading">Error</div>
                                                            <div class="panel-body">${msg.replace(/\n/g, '<br />')}</div>
                                                        </div>
                                                    </div>`;
                // window.alert(msg);
                return Promise.reject(e.message || e);
            }
        }

        let result = await this.ReadDefaultConfig();

        if (/embedded=1/.test(window.location.href))
            RuntimeInfo.Embedded = true;
        else
            RuntimeInfo.Embedded = this.GetConfigSetting('embedded');

        /* get second config if needed
        let cfgFile = this.getConfigName();
        if (cfgFile) {
            let responseMessage = await client.get(cfgFile);
            cfg = JSON.parse(responseMessage.response);
            Object.assign(environment, cfg);
        } */

        await this.SetupI18n(this.i18n);
        this.InitializeApp();

        return result;
    }

    public static InitializeApp() {
        // could have been set by url, so don't change if it is already set.
        if (UserService.ValidationRequired && RuntimeInfo.Embedded) {
            UserService.ValidationRequired = false;
        }

        if (ReportService.ReportServer) {
            ReportService.ReportServer = NitTools.IncludeTrailingSlash(ReportService.ReportServer);
        }

        RuntimeInfo.IsIE = this.isInternetExplorer();
        let htmlElement: HTMLElement = document.querySelector('html');
        let isMobile = false;

        // if not mobile param in url, get it from userAgent string ..
        if (!(/mobile=/g.test(window.location.href))) {
            let m = new md(window.navigator.userAgent);
            isMobile = !!m.mobile();

            // newer ipads identify as desktop browsers but have not 5 Touch-Points
            if (/.*iPhone .*/g.test(navigator.userAgent) && navigator.maxTouchPoints === 5) {
                isMobile = true;
                htmlElement.classList.add("iphone");
            } else if ((/.*Mac OS X.*/gi.test(navigator.userAgent) || /.*AppleWebKit.*/g.test(navigator.userAgent))
                && (navigator.maxTouchPoints >= 4 && navigator.maxTouchPoints < 10))
            {
                htmlElement.classList.add("ipad");
                isMobile = true;
            } else if (/.*iphone.*/gi.test(navigator.userAgent)) {
                isMobile = true;
                htmlElement.classList.add("iphone");
            }
        } else { // .. or isMobile is the value from mobile=[0|1]
            RuntimeInfo.IsMobile = /mobile=1/g.test(window.location.href);
        }

        RuntimeInfo.IsMobile = isMobile;

        if (window.location.href.indexOf('://localhost') > -1 || window.location.href.indexOf('://127.0.0.1') > -1) {
            htmlElement.classList.add('offline-version');
        }

        this.setMomentOptions();
        // this.setPickerOptions();
    }

    private static isInternetExplorer() {
        // funktioniert: return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./) || navigator.userAgent.toUpperCase().indexOf("EDGE") > -1;
        let ua = navigator.userAgent.toUpperCase();
        let msie = ua.indexOf("MSIE ");

        return msie > 0 || !!ua.match(/TRIDENT.*RV:11\./) || ua.indexOf("EDGE") > -1 || ua.indexOf("Windows NT") > -1 || ua.indexOf(".NET4.") > -1;
    }
}

export interface IEncounterTypeChoice {
    enabled: boolean;
    choices?: IEncounterTypeChoiceItem[];
    displayIcons : boolean;
}

export interface IEncounterTypeChoiceItem {
    target?: string;
    title?: string,
    analyzer: string;
    forms?: any;
}
